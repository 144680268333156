*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@font-face {
  font-family: "human_sans_700";
  src: url('../src/assets/Fonts/human_sans/HumanSans_OTF/HumanSans-Regular.otf');
}

@font-face {
  font-family: "human_sans_400_bold";
  src: url('../src/assets/Fonts/human_sans/HumanSans_OTF/HumanSans-Regular.otf');
}

@font-face {
  font-family: "human_sans_400";
  src: url('../src/assets/Fonts/human_sans/HumanSans_OTF/HumanSans-Light.otf');
}

@font-face {
  font-family: "human_sans_700_bold";
  src: url('../src/assets/Fonts/human_sans/HumanSans_OTF/HumanSans-Bold.otf');
}

@font-face {
  font-family: "roboto_condensed_700";
  src: url('../src/assets/Fonts/roboto-condensed/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: "roboto_condensed_400";
  src: url('../src/assets/Fonts/roboto-condensed/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: "roboto_condensed";
  src: url('../src/assets/Fonts/roboto-condensed/RobotoCondensed-Regular.ttf');
}